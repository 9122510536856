























import { Research } from '@app/models';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import ModuleSelect from '../../components/research/module-select.vue';
import ServiceSelect from '../../components/research/service-select.vue';
import { ModuleModule } from '../../store/modules/module-module';

@Component({
  components: { ModuleSelect, ServiceSelect },
})
export default class ResearchStructure extends Vue {
  @Prop()
  public value!: Research.Mutable;

  private module: ModuleModule = getModule(ModuleModule);

  public get price() {
    const { structure } = this.value;
    if (!structure || !this.value.target.length) return 0;

    const service = structure.service
      .map(id => this.module.services.find(s => id === s.id))
      .map(s => (s ? s.price : 0))
      .reduce<number>((acc, val) => acc + val, 0);

    const module = structure.module
      .map(id => this.module.find(id))
      .map(m => (m ? m.metadata.price : 0))
      .reduce<number>((acc, val) => acc + val, 0);

    return service + module;
  }
}
