






























import { Module } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';
import ResearchTargetTabs from './research-target-tabs.vue';

@Component({
  components: { ResearchTargetTabs },
})
export default class ModuleSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  public value!: string[];

  @Prop({ type: Array, default: () => [] })
  public targets!: string[];

  public target = this.targets[0];

  private module = getModule(ModuleModule);

  public get modules() {
    return this.module.active
      .filter(m => m.category.split('+').includes(this.target))
      .sort((a, b) => a.metadata.order - b.metadata.order);
  }

  public getDescription(module: Module) {
    return this.getLinkedModules(module)
      .map(m => m.description)
      .join('\n\n');
  }

  public getLinkedModules(module: Module) {
    return this.module.active.filter(m => {
      return (
        m.name === module.name &&
        this.targets.some(t => m.category.split('+').includes(t))
      );
    });
  }

  public getPrice(module: Module) {
    return this.getLinkedModules(module).reduce(
      (acc, val) => acc + val.metadata.price,
      0
    );
  }

  public getStructure(module: Module): Module['metadata']['structure'] {
    const s = this.getLinkedModules(module).map(m => m.metadata.structure);
    if (s.some(s => s === 'always-on')) return 'always-on';
    if (s.some(s => s === 'on-by-default')) return 'on-by-default';
    return 'off-by-default';
  }

  public toggle(module: Module) {
    const enable = !this.value.includes(module.id);
    const modules = this.getLinkedModules(module);

    if (!enable) {
      const value = this.value.filter(id => !modules.find(m => m.id === id));
      this.$emit('input', value);
      return;
    }

    const set = new Set([...this.value, ...modules.map(m => m.id)]);

    const value = this.module.active
      .sort((a, b) => a.metadata.order - b.metadata.order)
      .filter(m => this.getStructure(m) === 'always-on' || set.has(m.id))
      .map(m => m.id);

    this.$emit('input', value);
  }

  @Watch('targets')
  public watchTargets() {
    if (!this.targets.includes(this.target)) this.target = this.targets[0];
  }
}
