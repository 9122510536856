

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';

@Component
export default class ServiceSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  public value!: string[];

  private module = getModule(ModuleModule);

  public get services() {
    return this.module.services
      .filter(s => s.active)
      .sort((a, b) => a.order - b.order);
  }

  public toggle(id: string) {
    const service = this.services.find(s => s.id === id);
    if (!service || service.structure === 'always-on') return;

    const value = this.services
      .filter(s => {
        const cur = this.value.includes(s.id);
        return s.structure === 'always-on' || (s.id === id ? !cur : cur);
      })
      .map(s => s.id);

    this.$emit('input', value);
  }
}
